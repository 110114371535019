<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item aria-current="location" active>
              內容管理中心
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ displayText("upload_file.list_title", "檔案管理") }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">
          {{ displayText("upload_file.list_title", "檔案管理") }}
        </h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.FILE_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'UploadFileCreate' }"
            ><i class="fa fa-plus"></i>
            {{
              displayText("upload_file.creat_upload_file", "新增檔案")
            }}</b-button
          >
          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              :placeholder="
                displayText('upload_file.search_placeholder', '搜尋檔案名稱')
              "
              v-model="query.keyword"
              v-on:keyup.enter="handlePageReset"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            v-b-toggle.collapse-1-inner
            variant="link"
            class="flex-shrink-0 ml-0 ml-xl-1"
          >
            {{ displayText("upload_file.advanced_search", "進階搜尋") }}
            <i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
      <b-collapse id="collapse-1-inner" class="mt-2">
        <div class="border p-2">
          <b-form>
            <div class="d-flex flex-column flex-xl-row">
              <b-form-input
                v-model="query.keyword"
                :placeholder="
                  displayText('upload_file.search_placeholder', '搜尋檔案名稱')
                "
              ></b-form-input>
            </div>
            <div class="d-flex flex-column flex-xl-row mt-2">
              <!-- 時間區間 -->
              <DateSearchPicker
                class="mr-md-2 mr-0"
                v-model="query"
                :disabledEndAtDates.sync="disabledEndAtDates"
                :startedPlaceholder="
                  displayText(
                    'upload_file.search_started_placeholder',
                    'Start Date'
                  )
                "
                :endedPlaceholder="
                  displayText(
                    'upload_file.search_ended_placeholder',
                    'End Date'
                  )
                "
                @input="handleDateChange"
              />
            </div>
            <div class="d-flex flex-column flex-xl-row mt-2">
              <b-form-group
                :label="displayText('upload_file.field_type', '檔案類型')"
                label-cols-sm="1"
                content-cols-sm
                content-cols-lg="11"
                style="width: 100%"
              >
                <b-form-checkbox-group
                  id="extension-selected"
                  v-model="query.extension"
                  class="pt-1"
                  :options="extensionOptions"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>

            <div class="flex-column mt-2">
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="handlePageReset"
                ><i class="fa fa-search"></i>
                {{ displayText("upload_file.search_title", "搜尋") }}</b-button
              >
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="resetSearch"
                >{{
                  displayText("upload_file.reset_search_title", "重置搜尋條件")
                }}</b-button
              >
            </div>
          </b-form>
        </div>
      </b-collapse>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="uploadFiles"
                :fields="fields"
                show-empty
              >
                <template #cell(info)="data">
                  <div class="row">
                    <div class="col-2 mr-3" v-if="data.item.file.extension">
                      {{ data.item.file.extension.toUpperCase() }}
                    </div>
                    <div class="copy-panel col-2">
                      <div
                        id="copy-tooltip-target"
                        class="copy-button"
                        v-clipboard:copy="data.item.liff_url"
                        @click="clipboardSuccessHandler(data.item)"
                        v-b-tooltip.click="
                          displayText('upload_file.copy_success', '已複製')
                        "
                      >
                        <i
                          :class="copyIconClass(data.item)"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    v-if="checkPermission([consts.FILE_LIST_VIEW])"
                    variant="inverse-primary"
                    :to="{
                      name: 'UploadFileView',
                      params: { upload_file_id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-eye mr-1"></span>
                    {{ displayText("upload_file.view_text", "查看") }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.FILE_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'UploadFileEdit',
                      params: { upload_file_id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil mr-1"></span>
                    {{ displayText("upload_file.edit_text", "編輯") }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.FILE_DELETE])"
                    variant="inverse-danger"
                    @click="deleteUploadFile(data.item)"
                  >
                    <span class="mdi mdi-delete mr-1"></span>
                    {{ displayText("upload_file.delete_text", "刪除") }}
                  </b-button>
                </template>
                <template #empty>
                  <div class="text-center text-muted">
                    <p>查無資料</p>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import uploadFileApi from "@/apis/upload-file";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import moment from "moment";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { displayText } from "@/utils/dashboardUtils";

Vue.use(VueClipboard);

export default {
  components: { CustomPagination, DateSearchPicker },
  mixins: [paginationMixin],
  data() {
    return {
      consts: consts,
      totalRows: 0,
      showLoading: true,
      uploadFiles: [],
      categoryOptions: [],
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        start_at: "",
        end_at: "",
        keyword: "",
        extension: [
          "pdf",
          "xlsx",
          "ppt",
          "doc",
          "video",
          "image",
          "txt",
          "other",
        ],
      },
      extensionOptions: [
        {
          value: "pdf",
          text: "PDF",
        },
        {
          value: "xlsx",
          text: "XLSX",
        },
        {
          value: "ppt",
          text: "PPT",
        },
        {
          value: "doc",
          text: "DOC",
        },
        {
          value: "video",
          text: "Video",
        },
        {
          value: "image",
          text: "Image",
        },
        {
          value: "txt",
          text: "TXT",
        },
        {
          value: "other",
          text: "Other",
        },
      ],
      fields: [
        {
          key: "name",
          label: displayText("upload_file.field_name", "檔案名稱"),
        },
        {
          key: "description",
          label: displayText("upload_file.field_description", "描述"),
          formatter: (value) => {
            value = value || "";
            const splittedValue = [...value];
            if (splittedValue.length <= 10) {
              return value;
            }
            return splittedValue.slice(0, 10).join("") + "...";
          },
        },
        {
          key: "file.size",
          label: displayText("upload_file.field_size", "檔案大小"),
          formatter: (value) => {
            if (value) return (value / 1024 / 1024).toFixed(2) + " MB";
            return "-";
          },
        },
        {
          key: "staff.name",
          label: displayText("upload_file.field_staff_name", "上傳者"),
        },
        {
          key: "info",
          label: displayText("upload_file.field_type", "檔案類型"),
        },
        {
          key: "category",
          label: displayText("upload_file.field_category_type", "檔案分類"),
          formatter: (value) => {
            if (value) {
              if (
                this.categoryOptions.find((options) => options.value === value)
              ) {
                return this.categoryOptions.find(
                  (options) => options.value === value
                ).text;
              } else {
                return displayText(
                  "upload_file.field_category_type_not_set",
                  "無法分類"
                );
              }
            }
          },
        },
        {
          key: "start_at",
          label: displayText("upload_file.field_start_at", "開始時間"),
          formatter: (value) => {
            if (value) return moment(value).format("YYYY-MM-DD");
            return "-";
          },
        },
        {
          key: "end_at",
          label: displayText("upload_file.field_end_at", "結束時間"),
          formatter: (value) => {
            if (value) return moment(value).format("YYYY-MM-DD");
            return "-";
          },
        },
        {
          key: "actions",
          label: displayText("upload_file.field_action", "管理"),
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    disabledEndAtDates() {
      return { to: new Date(this.query.start_at) };
    },
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}-${this.query.keyword}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.getUploadFiles();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.init();
  },
  methods: {
    displayText,
    copyIconClass(item) {
      if (!item.copied) {
        return "fa fa-copy";
      } else {
        return "fa fa-check";
      }
    },
    resetSearch() {
      this.query = {
        page: 1,
        per_page: 10,
        start_at: "",
        end_at: "",
        keyword: "",
        extension: [
          "pdf",
          "xlsx",
          "ppt",
          "doc",
          "video",
          "image",
          "txt",
          "other",
        ],
      };
      this.handlePageReset();
    },
    handleDateChange(date) {
      const formatDate = (date) => {
        if (!date) return null;
        if (typeof date === "string") return date;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      this.query.start_at = formatDate(date.start_at);
      this.query.end_at = formatDate(date.end_at);
    },

    async init() {
      await this.getUploadFiles();
      this.fetchCategoryOptions();
      this.initialized = true;
    },
    fetchCategoryOptions() {
      const data =
        this.getModuleConfig("upload_files", "upload_files_category") ?? false;
      if (!data) return;
      this.categoryOptions = [
        ...data
          .sort((a, b) => a.order - b.order)
          .map((source) => {
            return {
              text: source.text,
              value: source.key,
            };
          }),
      ];
    },
    async getUploadFiles() {
      this.showLoading = true;
      try {
        let params = {
          ...this.query,
        };

        const { data } = await uploadFileApi.getUploadFiles(
          this.organization,
          params
        );
        this.uploadFiles = data.data.map((data) => {
          return {
            ...data,
            copied: false,
          };
        });
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: displayText(
              "upload_file.api_get_error_response_text",
              "取得檔案資料錯誤"
            ),
          });
        }
      }
      this.showLoading = false;
    },
    async deleteUploadFile(uploadFile) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>檔案：${uploadFile.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await uploadFileApi.removeUploadFile(
              this.organization,
              uploadFile
            );
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + uploadFile.name,
              });
              this.getUploadFiles();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    clipboardSuccessHandler(item) {
      item.copied = true;
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}

.copy-button {
  background: rgba(102, 153, 204, 0.03);
  border: 1px solid rgba(102, 153, 204, 0.6);
  color: #3973ac;
  padding: 4px 16px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  position: absolute;
  top: -7px;
}

.copy-panel {
  position: relative;
}
</style>
